import { getUrlPrefix, httpApiRequestDefault } from './service'

export const register = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/auth/register`,
    requestBody,
  })
}
/**
 *
 * @param {*} param0 login({ data: { email: '', password: '' } })
 * @returns
 */
export const login = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/auth/login`,
    requestBody,
  })
}
/**
 *
 * @param {*} param0
 * @returns response { isLogin: false }
 */
export const getIsLogin = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/user/auth/check_login`,
    requestBody,
  })
}
/**
 *
 * @param {*} noParam
 * @returns
 */
export const logout = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'delete',
    url: `${getUrlPrefix()}/user/auth/logout`,
    requestBody,
  })
}
/**
 *
 * @param {*} param0 sendEmailVerifyCode({ data: { email: '' } })
 * @returns
 */
export const sendEmailVerifyCode = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/auth/pw_token`,
    requestBody,
  })
}
/**
 *
 * @param {*} param0 enterEmailVerifyCode({ data: { token: '' } })
 * @returns
 */
export const enterEmailVerifyCode = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/auth/pw_verify`,
    requestBody,
  })
}

/**
 * 定期更改密碼須先通過登入，忘記密碼需先打前二驗證碼。
 * resetPassword({ data: { password: '' } })
 */
export const resetPassword = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/auth/pw_reset`,
    requestBody,
  })
}
/**
 * 定期更改密碼須先通過登入，忘記密碼需先打前二驗證碼。
 * checkUserEmailExist({ query: { email: '' } })
 *
 * @returns result { exist: Boolean }
 */
export const checkUserEmailExist = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/user/user_check/email`,
    requestBody,
  })
}

/**
 * 
 * @param {*} createReferrers({
 *  data: {
    "uid": ""
  }
 * })
 * @returns 
 */
export const registerVerify = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/auth/register_verify`,
    requestBody,
  })
}
/**
 * otp 等步驟密碼驗證
 * @param {*} verifyPassword({
 *  data: {
    action: "WITHDRAW_APPLY" | "TRADER_CERT_APPLY" | "TRADER_CERT_DELETE"
    password: ''
  }
 * })
 * @returns 
 */
export const verifyPassword = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/pwd/verify`,
    requestBody,
  })
}
/**
 * 
 * @param {*} sendOtp({
 *  data: {
    action: "WITHDRAW_APPLY" | "TRADER_CERT_APPLY" | "TRADER_CERT_DELETE"
  }
 * })
 * @returns 
 */
export const sendOtp = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/otp`,
    requestBody,
  })
}

/**
 * 
 * @param {*} verifyPassword({
 *  data: {
    action: "WITHDRAW_APPLY" | "TRADER_CERT_APPLY" | "TRADER_CERT_DELETE"
    otp: ''
  }
 * })
 * @returns 
 */
export const verifyOtp = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/otp/verify`,
    requestBody,
  })
}
