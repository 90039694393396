<template>
  <v-btn
    :class="[buttonClass, themeSetting.themeClass]"
    v-bind="$attrs"
    :width="width"
    :height="height"
    style="border-radius: 6px"
    :disabled="disabled"
    :color="themeSetting.color"
    @click="(e) => $emit('click', e)"
    depressed
  >
    <AlText
      :class="[disabled ? '' : themeSetting.text, textClass, textAppendClass]"
      :size="textSize"
      block
    >
      <slot></slot>
    </AlText>
  </v-btn>
</template>
<script>
// bg: vuetify register color name,
// text: class name of text
const themeEnum = {
  primary: 'primary',
  primarySub: 'primary-sub',
  primaryOutline: 'primary-outline',
  dangerSub: 'danger-sub',
  dangerOutline: 'danger-outline',
  info: 'info',
}
// color includes bg and border
const themeConfig = {
  [themeEnum.primary]: {
    color: 'al-primary-600',
    text: 'text-al-grey-100',
  },
  [themeEnum.primarySub]: {
    color: 'al-primary-100',
    text: 'text-al-primary-600',
  },
  [themeEnum.primaryOutline]: {
    text: 'text-al-primary-600',
    themeClass: '!bg-al-grey-100 border border-solid border-al-primary-600',
  },
  [themeEnum.dangerSub]: {
    color: 'al-error-sub',
    text: 'text-al-error',
  },
  [themeEnum.dangerOutline]: {
    text: 'text-al-error',
    themeClass: '!bg-al-grey-100 border border-solid border-al-error',
  },
  [themeEnum.info]: {
    color: 'al-grey-300',
    text: 'text-al-grey-700',
  },
}
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: themeEnum.primary,
    },
    width: {
      type: [String, Number],
      default: 'auto',
    },
    height: {
      type: [String, Number],
      default: 'auto',
    },
    // text class will has textClass and theme's class
    textClass: {
      type: String,
      // font-weight 600
      default: 'font-semibold indent-0 tracking-normal',
    },
    textAppendClass: {
      type: String,
      default: undefined,
    },
    textSize: {
      type: String,
      default: '14',
    },
    buttonClass: {
      type: String,
      default: '!py-8px !px-24px',
    },
  },
  computed: {
    themeSetting() {
      if (!this.theme || !themeConfig[this.theme])
        return themeConfig[themeEnum.primary]
      return themeConfig[this.theme]
    },
  },
}
</script>
