export default [
  {
    path: '/open-account-basic',
    name: 'open-account-basic',
    component: () =>
      import(
        /* webpackChunkName: "page-open-account-basic" */ '@/views/open-account-basic.vue'
      ),
    meta: {
      title: '開戶基本資料',
    },
  },
  {
    path: '/sign-contract',
    component: () =>
      import(
        /* webpackChunkName: "layout-empty" */ '@/layouts/layout-empty.vue'
      ),
    meta: {
      title: '線上開戶申請-文件簽署',
    },
    children: [
      {
        path: '',
        name: 'sign-contract',
        component: () =>
          import(
            /* webpackChunkName: "page-sign-contract" */ '@/views/sign-contract'
          ),
        meta: {
          title: '線上開戶申請-文件簽署',
        },
      },
      {
        path: 'result',
        name: 'sign-contract-result',
        component: () =>
          import(
            /* webpackChunkName: "page-sign-contract-result" */ '@/views/sign-contract/result.vue'
          ),
        meta: {
          title: '線上開戶申請-文件簽署',
        },
      },
    ],
  },
  {
    path: '/credential-upload',
    name: 'credential-upload',
    component: () =>
      import(
        /* webpackChunkName: "page-credential-upload" */ '@/views/credential-upload.vue'
      ),
    meta: {
      title: '線上開戶申請-證明文件',
    },
  },
  {
    path: '/bank-account',
    name: 'bank-account',
    component: () =>
      import(
        /* webpackChunkName: "page-bank-account" */ '@/views/bank-account.vue'
      ),
    meta: {
      title: '線上開戶申請-出入金帳戶',
    },
  },

  {
    path: '/kyc',
    name: 'kyc',
    component: () => import('@/views/kyc.vue'),
    meta: {
      title: '線上開戶申請-基本資料',
    },
  },
  {
    path: '/risk-assessment-questionnaire',
    name: 'risk-assessment-questionnaire',
    component: () => import('@/views/risk-assessment-questionnaire.vue'),
    meta: {
      title: '線上開戶申請-客戶投資風險承受度分析紀錄表',
    },
  },
  {
    path: '/tax-identity',
    name: 'tax-identity',
    component: () => import('@/views/tax-identity.vue'),
    meta: {
      title: '線上開戶申請-課稅身份別',
    },
  },
]
