<template>
  <div class="text-input-default-1_container" :style="{ width: inputWidth }">
    <div v-if="!hideLabelPlace" class="text-input-default-1_label">
      <span>{{ label }}</span>
      <!-- 為了去除換行導致 * 多空格  -->
      <template v-if="required && label">
        <span class="text-input-default-1_require-mark">*</span>
      </template>
      <slot name="label-suffix"></slot>
    </div>
    <div class="relative">
      <input
        v-bind="$attrs"
        :style="{ height: inputHeight, ...inputExtendStyle }"
        class="text-input-default-1_input bg-al-grey-100"
        :class="[
          extendClass,
          dense ? 'h-40px px-12px py-8px' : 'h-48px p-12px',
          disabled ? 'disabled' : '',
        ]"
        :placeholder="placeholder"
        @input="onInputByMode"
        :value="value"
        :disabled="disabled"
        @blur="(e) => $emit('blur', e)"
        @focus="(e) => $emit('focus', e)"
        @keypress="(e) => $emit('keypress', e)"
      />
      <slot name="input-decorate"></slot>
    </div>
    <div
      v-if="!hideDetails"
      class="text-input-default-1_helper-text"
      :class="extendClass"
    >
      <span v-if="helperText">{{ helperText }}</span>
    </div>
  </div>
</template>
<script>
import typeEnum from './typeEnum'
const { theme: themeEnum } = typeEnum
export default {
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    // 上方標題
    label: {
      type: String,
      default: '',
    },
    // 是否必填（是的話若有標題，標題旁多出紅色星號）
    required: {
      type: Boolean,
      default: false,
    },
    // 幫助文字（提示 or 錯誤訊息）
    helperText: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: themeEnum.default,
    },
    // 若為 string 則直接使用，若為 number 則默認為 px 數值.
    height: {
      type: [String, Number],
      default: '',
    },
    width: {
      type: [String, Number],
      default: '',
    },
    // 根據 inputEventFull 參數會不同
    // onInput: {
    //   type: Function,
    //   default: () => {},
    // },
    /**
     * true: 執行 onInput 時傳入整個 event 參數.
     * false: 執行 onInput 時只傳入值（event.target.value）當參數
     */
    inputEventFull: {
      type: Boolean,
      default: false,
    },
    // 高密度版本 （縮小高度）
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputExtendStyle: {
      type: Object,
      default: () => {},
    },
    // 隱藏 label 區塊
    hideLabelPlace: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // for vue dynamic class
    extendClass() {
      switch (this.theme) {
        case themeEnum.error: {
          return { 'is-error': true }
        }
        case themeEnum.default:
        default: {
          return ''
        }
      }
    },
    inputHeight() {
      if (typeof this.height === 'number') {
        return `${this.height}px`
      }
      // string or undefined
      return this.height
    },
    inputWidth() {
      if (typeof this.width === 'number') {
        return `${this.width}px`
      }
      // string or undefined
      return this.width
    },
    onInputByMode() {
      if (this.inputEventFull) {
        return (e) => this.$emit('input', e)
      }
      return (e) => this.$emit('input', e.target.value)
    },
  },
}
</script>
<style lang="scss">
.text-input-default-1_container {
  width: 100%;
}
.text-input-default-1_input {
  width: 100%;
  outline: 1px solid $color-grey-600;
  border-radius: 6px;
  transition: all 0.2s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  @include font('base');
  &:focus,
  &:focus-visible {
    outline-color: $color-primary-600;
  }
  &.is-error {
    outline-color: $color-error;
  }

  &::placeholder {
    color: $color-grey-600;
  }

  &.disabled {
    background-color: $color-grey-200;
    color: $color-grey-600;
  }
}

.text-input-default-1_label {
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
  display: flex;
  @include font('xs');
  @include height-by-size('xs');
}

.text-input-default-1_require-mark {
  color: $color-error;
}

.text-input-default-1_helper-text {
  @include font('xs');
  color: $color-warning;
  width: 100%;
  min-height: 16px;
  margin-top: 4px;
  text-align: left;
  &.is-error {
    color: $color-error;
  }
}
</style>
