import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import view from './view'
import notification from './notification'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { auth, view, notification },
})
