const state = {
  registerViewModel: {
    referrerId: '',
  },
}

const getters = {
  registerViewModel: (state) => {
    return state.registerViewModel
  },
}

const actions = {}

const mutations = {
  setRegisterViewModel(state, registerViewModel) {
    state.registerViewModel = registerViewModel
  },
  updateRegisterViewModel(state, particalRegisterViewModel) {
    state.registerViewModel = {
      ...state.registerViewModel,
      ...particalRegisterViewModel,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
