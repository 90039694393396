import { middlewareLogin } from '@/middleware/router/auth'
export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      middleware: {
        middlewaresBeforeEach: [middlewareLogin],
      },
      title: '登入',
      isPublic: true,
    },
    component: () =>
      import(/* webpackChunkName: "page-login" */ '@/views/login.vue'),
  },
  {
    path: '/register',
    component: () =>
      import(
        /* webpackChunkName: "layout-layout-empty" */ '@/layouts/layout-empty'
      ),
    children: [
      {
        path: '',
        name: 'register',
        meta: {
          middleware: {
            middlewaresBeforeEach: [middlewareLogin],
          },
          title: '註冊',
          isPublic: true,
        },
        component: () =>
          import(/* webpackChunkName: "page-register" */ '@/views/register'),
      },
      {
        path: 'verify',
        name: 'register-verify',
        meta: {
          middleware: {
            middlewaresBeforeEach: [middlewareLogin],
          },
          title: '註冊驗證信箱',
          isPublic: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "page-register-verify" */ '@/views/register/verify'
          ),
      },
    ],
  },
]
