import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/main.css'
import '@/assets/tailwind.css'
import '@/assets/scss/main.scss'
import { showSingleNotification } from '@/services/notification'

import { middlewareRouterBeforeEach } from './router/service/middleware'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = process.env.NODE_ENV === 'development'

// 注入需要的全局組件
const requireComponent = require.context(
  // The relative path of the components folder
  './components/global',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  // 目前是注入 .vue 檔
  /[A-Za-z]\w+\.(vue)$/
)

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName)
  const fileNamesSplitFolder = fileName.split('/')
  // if name is index, let the previous folder name be component name
  const fileNameTarget =
    fileNamesSplitFolder[fileNamesSplitFolder.length - 1].split('.')[0] ===
    'index'
      ? fileNamesSplitFolder[fileNamesSplitFolder.length - 2]
      : fileNamesSplitFolder[fileNamesSplitFolder.length - 1].replace(
          /\.\w+$/,
          ''
        )
  // Get PascalCase name of component
  const componentName = upperFirst(camelCase(fileNameTarget))
  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

router.beforeEach(middlewareRouterBeforeEach)

// api empty id, if it is int64
Vue.prototype.$DEFAULT_ID = null
Vue.prototype.$DEFAULT_API_ERROR_TEXT = '發生錯誤，請確認後重新嘗試。'
Vue.prototype.$showSingleNotification = showSingleNotification

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
