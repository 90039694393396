// import userConfig from '@/config/user'
// const {
//   permissionEnum: { PERMISSION_FAKE },
// } = userConfig
export default [
  {
    path: '/',
    name: 'index',
    component: () =>
      import(/* webpackChunkName: "page-index" */ '@/views/index.vue'),
    meta: {
      title: '首頁',
    },
  },
  {
    path: '/user-data',
    name: 'user-data',
    component: () =>
      import(/* webpackChunkName: "page-user-data" */ '@/views/user-data.vue'),
    meta: {
      title: '個人資料',
    },
  },
  {
    path: '/trader-account',
    name: 'trader-account',
    component: () =>
      import(
        /* webpackChunkName: "page-trader-account" */ '@/views/trader-account.vue'
      ),
    meta: {
      title: '交易帳號',
    },
  },

  {
    path: '/withdraw-orders',
    name: 'withdraw-orders',
    component: () =>
      import(
        /* webpackChunkName: "page-withdraw-orders" */ '@/views/withdraw-orders.vue'
      ),
    meta: {
      title: '出金紀錄',
    },
  },

  // meta: {
  //   permission: {
  //     [PERMISSION_FAKE]: true,
  //   },
  // },
]
