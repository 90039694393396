import { getUrlPrefix, httpApiRequestDefault } from './service'

/**
 * 
 * @param {*} param0 
 * @returns activeUser 
 * {
    "user_id": "1062533843002589287",
    "account": "testuser@otsogroup.com",
    "name": "大白",
    "referrer_id": "0",
    "enable": true,
    "email": "white@otsogroup.com",
    "phone": ""
}
 */
export const getActiveUser = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/user`,
    requestBody,
  })
}
/**
 * 
 * @param {*} param0 
 * @returns reffers 
 * {
    "referrers": [
        {
            "id": "777",
            "name": "測試推廣人2",
            "email": "referrer_2@test.com"
        },
        {
            "id": "999",
            "name": "測試推廣人1",
            "email": "referrer_1@test.com"
        }
    ]
}
 */
export const getReferrers = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/user/referrers`,
    requestBody,
  })
}
/**
 * 取得用戶的銀行帳號（最後一次通過審核）
 * @param {*} param0 
 * @returns reffers 
 * {
    "bank_accounts": [
        {
            "id": "14331",
            "user_id": "423423342",
            "bank_name": "瑞興商業銀行",
            "bank_code": "101",
            "bank_branch": "總行",
            "account_number": "324324",
            "account_image": "/u/324342/1116837828777476188.jpg",
            "is_default": false,
            "created_at": "2022-11-02T19:03:16.947Z",
            "updated_at": "2023-03-30T11:37:29.083Z"
        },
    ]
}
 */
export const getBankAccounts = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/user/bank_accounts`,
    requestBody,
  })
}
/**
 * 
 * @param {*} param0 
 * @returns reffers 
 * {
    total: "20",
    "orders": [
        {
            "id": "342324",
            "user_id": "32434",
            "login": "234432",
            "amount": "100000000000",
            "status": "FAILED",
            "kind": "SCHEDULED",
            "comment": "出金金額不足（網頁）",
            "bank_account": {
                "BankName": "臺灣新光商業銀行",
                "BankCode": "103",
                "BankBranch": "東台北分行",
                "AccountNumber": "121312323"
            },
            "deal_id": null,
            "created_at": "2023-07-25T10:30:10.667Z",
            "updated_at": "2023-07-25T10:30:10.667Z"
        }
    ],
}
 */
export const getWithdrawOrders = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/user/withdraw/orders`,
    requestBody,
  })
}

/**
 * @param {*} param0 cancelWithdrawOrder({
 * pathParams: {
 *  orderId: '123456'
 * },
 * })
 * @returns response
 */
export const cancelWithdrawOrder = ({ pathParams, ...requestBody } = {}) => {
  const { orderId } = pathParams

  return httpApiRequestDefault({
    method: 'patch',
    url: `${getUrlPrefix()}/user/withdraw/orders/${orderId}/cancel`,
    requestBody,
  })
}
/**
 * 
 * @param {*} param0 
 * @returns reffers 
 * {
    "industries": [
        "一般製造業",
        "化學材料、化學製品製造業",
    ]
  }
 */
export const getIndustries = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/user/apply_steps/industries`,
    requestBody,
  })
}
