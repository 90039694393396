import axios from '@/network/axios'
import { parseKeyToSnakeCase, parseKeyToCamelCase } from '@/helper'
import { showSingleNotification } from '@/services/notification'

const getUrlPrefix = () => {
  if (process.env.NODE_ENV === 'development') {
    return '/local-proxy-api'
  }
  // return `https://${window.REQUEST_DOMAIN}`
  return '/api'
}

const DEFAULT_RESPONSE_TYPE = 'default'
const ALL_RESPONSE_TYPE = 'all'

/**
 * 自定義 customConfig
 * bodyType- all: 返回所有 axios response, default(不填預設): 返回 axios data
 */
const httpApiResponseDefault = async (
  requestConfig,
  option = {
    response: {},
  }
) => {
  try {
    const {
      response: { bodyType = DEFAULT_RESPONSE_TYPE, skipFileParse = false },
    } = option
    const response = await axios(requestConfig)
    switch (bodyType) {
      // 回傳包括 header 等等的所有 axios response
      case ALL_RESPONSE_TYPE: {
        return {
          ...response,
          data: parseKeyToCamelCase(
            response.data,
            skipFileParse
              ? (data) =>
                  typeof data !== 'object' ||
                  data instanceof File ||
                  data instanceof Blob
              : undefined
          ),
        }
      }
      // 預設只返回 api 回傳的資料
      case DEFAULT_RESPONSE_TYPE: {
        return parseKeyToCamelCase(
          response.data,
          skipFileParse
            ? (data) =>
                typeof data !== 'object' ||
                data instanceof File ||
                data instanceof Blob
            : undefined
        )
      }
      default: {
        return Promise.reject('got wrong response type')
      }
    }
  } catch (error) {
    const errorBody = Promise.reject(error)
    // { config: xxx, data: xxx, headers: xxx, request: xxx, status: xxx, statusText: xxx }
    // data: { error: { code: '', message: '', details: [] }}
    const { data, status } = error
    if (!data?.error) {
      showSingleNotification({
        icon: 'error',
        text: '發生不明錯誤，請重新嘗試',
      })
      return errorBody
    }
    const { code, message } = data.error
    // 未登入情形，不跳錯誤
    if (code === '1000') return errorBody
    if (Math.floor(status / 100) === 5) {
      showSingleNotification({
        icon: 'error',
        text: `系統發生錯誤，請重新嘗試
        code: ${code},
        message: ${message}`,
      })
      return errorBody
    }

    showSingleNotification({
      icon: 'error',
      text: `請確認後重新嘗試
      ${code}: ${message}`,
    })
    return errorBody
  }
}

const httpApiRequestDefault = (
  { method, url, requestBody = {} },
  option = { request: {}, response: {} }
) => {
  const { data, ...config } = requestBody
  return httpApiResponseDefault(
    {
      method,
      url,
      data: parseKeyToSnakeCase(data),
      ...config,
    },
    option
  )
}

const httpApiRequestFormData = (
  { method, url, requestBody = {} },
  option = { request: {}, response: {} }
) => {
  const { data, ...config } = requestBody
  console.log('httpApiRequestFormData config', config)
  console.log('httpApiRequestFormData requestBody', requestBody)

  const target = parseKeyToSnakeCase(
    data,
    (item) =>
      typeof item !== 'object' || item instanceof File || item instanceof Blob
  )
  const formDataPayload = new FormData()
  Object.keys(target).forEach((key) => {
    if (Array.isArray(target[key])) {
      target[key].forEach((item) => formDataPayload.append(key, item))
      return
    }
    formDataPayload.append(key, target[key])
  })
  return httpApiResponseDefault(
    {
      method,
      url,
      data: formDataPayload,
      headers: { 'Content-Type': 'multipart/form-data' },
      ...config,
    },
    {
      ...option,
      response: {
        ...option.response,
        skipFileParse: true,
      },
    }
  )
}

export { getUrlPrefix, httpApiRequestDefault, httpApiRequestFormData }
