// axios
import axios from 'axios'
import router from '@/router'
import store from '@/store'

const axiosInstance = axios.create({
  withCredentials: true,
  // You can add your headers here
})

// 判斷使用者是否登入的 api
// const isCheckLoginFunction = (apiUrl) => _endsWith(apiUrl, '/user')
const isNotLoginError = (axiosError) => {
  const errorCode = axiosError?.response?.data?.error?.code
  // 5014 目前後端上版丟失 session 會發生，也先導回登入頁
  return errorCode === '1000' || errorCode === '5014'
}
axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (
      isNotLoginError(error) &&
      router.history.current.name &&
      !['login', 'register'].includes(router.history.current.name)
    ) {
      store.commit('auth/setActiveUser', undefined)
      router.push({ name: 'login' })
    }
    return Promise.reject(error.response)
  }
)
export default axiosInstance
