import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import zhHant from 'vuetify/lib/locale/zh-Hant'
import {
  VApp,
  VBtn,
  VCheckbox,
  VTextField,
  VSwitch,
  VSelect,
  VIcon,
  VRadio,
  VRadioGroup,
  VDialog,
  VTooltip,
  VMenu,
  VList,
  VListItem,
} from 'vuetify/lib'
// hardcode let material be round class
import './material-icon/index.css'
import './material-icon/material-to-round.css'
import './vuetifyCustom.css'

// the name used original in template is <v-xxxx>
// the Vxx2 is using custom icon
Vue.use(Vuetify, {
  components: {
    VApp,
    VBtn,
    VCheckbox,
    VTextField,
    VSwitch,
    VMenu,
    VSelect,
    VSelect2: {
      extends: VSelect,
      mixins: [
        {
          props: {
            'append-icon': { default: 'keyboard_arrow_down' },
          },
        },
      ],
    },
    VIcon,
    VRadio,
    VRadioGroup,
    VDialog,
    VTooltip,
    VList,
    VListItem,
  },
})

export default new Vuetify({
  lang: {
    locales: { zhHant },
    current: 'zhHant',
  },
  theme: {
    themes: {
      light: {
        anchor: 'inherit',
        primary: '#2662C2',
        'al-primary-100': '#E9EFF9',
        'al-primary-600': '#2662C2',
        'al-primary-700': '#1E4E9B',
        'al-grey-100': '#FFFFFF',
        'al-grey-500': '#C8C8C8',
        'al-grey-600': '#C1C1C1',
        'al-secondary-600': '#469C9B',
        'al-error': '#F46A6A',
        'al-error-sub': '#FDE1E1',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
})
