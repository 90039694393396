import store from '@/store'
import { getIsLogin } from '@/network/httpApi/auth'

// eslint-disable-next-line no-unused-vars
const middlewareUpdate = async ({ to, from }) => {
  if (
    // 檢查用戶是否已登入
    !store.state.auth.activeUser &&
    // 避免無限重定向
    !to.meta?.isPublic
  ) {
    try {
      const responseIsLogin = await getIsLogin()
      const { isLogin } = responseIsLogin
      if (isLogin) {
        await store.dispatch('auth/initActiveUser')
      }
    } catch (error) {
      console.log(error)
    }
    if (!store.state.auth.activeUser) {
      console.log('update user error')
      return { name: 'login' }
    } else if (!store.state.auth.activeUser.enable) {
      console.log("user isn't enable")
      return { name: 'login' }
    }
    return true
  }
}

/**
 * for login page check
 * @param {*} param0
 * @returns
 */
// eslint-disable-next-line no-unused-vars
const middlewareLogin = async ({ to, from }) => {
  try {
    const responseIsLogin = await getIsLogin()
    const { isLogin } = responseIsLogin

    if (!isLogin) {
      if (store.state.auth.activeUser) {
        store.commit('auth/setActiveUser', undefined)
      }
      return true
    } else {
      try {
        await store.dispatch('auth/initActiveUser')
        if (!store.state.auth.activeUser) return false
        return { name: 'index' }
      } catch (error) {
        return false
      }
    }
  } catch (error) {
    return false
  }
}

export { middlewareLogin, middlewareUpdate }
